import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import GameRoom from './components/GameRoom';
import { io } from 'socket.io-client';

// Get the socket URL based on the environment
const socketUrl = process.env.REACT_APP_SOCKET_URL;

const socket = io(socketUrl);
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game/:roomId" element={<GameRoom socket={socket} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
