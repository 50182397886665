import React from 'react';

const Popup = ({ correctAnswer, playerScores }) => {
  let cnt = 1;
  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>Scores!</h2>
        <h3>Correct Answer: {correctAnswer}</h3>
        <ul>
          {playerScores.map(player => (
            <li key={player.playerId} className={`${player.curRoundScore === 1 ? 'hit': 'miss'}`}>
              <strong>{cnt++} : </strong> {player.name} <br />
              <strong>Time:</strong>  {`${player.curRoundScore === 1 ? player.curRoundTime.toFixed(2) +" seconds": 'NA'}`} 
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Popup;
